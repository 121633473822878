import styled from '@emotion/styled';
import css from '@emotion/css';
import { hex2rgba } from '../utils';

const StandardSection = styled.section`
	min-height: 400px;
	width: 100%;
	position: relative;

	${(props) => props.withBackground && css`background: ${hex2rgba(props.theme.colors.secondary.light, 0.1)};`};
`;

export default StandardSection;
