import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Button from "./Button"

const StyledNav = styled.nav`
  > a {
    font-size: 16px;
    color: ${props => props.theme.colors.common.white};
    margin-left: 24px;
    margin-right: 24px;
    text-decoration: none;
    &:last-child {
      margin: 0;
    }
    transition: all 300ms;
    &:hover {
      color: ${props => props.theme.colors.primary.main};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`

const Navigation = () => {
  return (
    <StyledNav>
      <Link to="/#services">Services</Link>
      <a
        href="https://www.notion.so/plukke/PaaS-Plukke-One-Click-d04347d6d54046e39122ec8678e8d809"
        target="_blank"
        rel="noopener noreferrer"
      >
        Solutions
      </a>
      <Link to="/portfolio">Portfolio</Link>
      <a href="https://dev.to/plukke" target="_blank" rel="noopener noreferrer">
        Blog
      </a>
      <Button as={Link} to="/#contact-us">
        Contact Us
      </Button>
    </StyledNav>
  )
}

export default Navigation
