import React from "react"
import css from "@emotion/css"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const RootInput = styled.div`
  position: relative;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1875em;

  ${props =>
    props.as === "textarea" &&
    css`
      padding: 18.5px 14px;
    `};
`

const OutlinedFieldset = styled.fieldset`
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  pointer-events: none;

  padding-left: 8px;
  border-color: rgba(255, 255, 255, 1);

  ${props =>
    props.isFocused &&
    css`
      border-width: 2px;
      border-color: ${props.theme.colors.primary.main};
    `};
`

const BaseInput = styled.input`
  font: inherit;
  color: white;
  width: 100%;
  border: 0;
  height: 1.1875em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;
  padding: 18.5px 14px;
  outline: none;

  &:focus {
    color: ${props => props.theme.colors.primary.main};
  }

  ${props =>
    props.as === "textarea" &&
    css`
      height: auto;
      resize: none;
    `};
`

const Legend = styled.legend`
  width: ${props => `${props.width}px` || "40px"};
  padding: 0;
  text-align: left;
  transition: width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  line-height: 11px;
`

const Label = styled.label`
  color: rgba(255, 255, 255, 1);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;

  display: block;

  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);

  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;

  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;

  transform: translate(14px, -6px) scale(0.75);

  ${props =>
    props.isFocused &&
    css`
      color: ${props.theme.colors.primary.main};
    `};
`

const FormControl = styled.div`
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  margin: 8px 0;
  width: 100%;
`

const Input = ({
  name,
  placeholder,
  label,
  type,
  value,
  onChange,
  onBlur,
  ariaLabel,
}) => {
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  const [isFocused, setFocused] = React.useState(false)
  return (
    <FormControl>
      <Label ref={inputLabel} isFocused={isFocused} htmlFor={name}>
        {label}
      </Label>
      <RootInput>
        <OutlinedFieldset isFocused={isFocused}>
          <Legend width={labelWidth}>
            <span>&#8203;​</span>
          </Legend>
        </OutlinedFieldset>
        {type !== "textarea" ? (
          <BaseInput
            id={name}
            name={name}
            value={value}
            aria-label={ariaLabel}
            type={type || "text"}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={e => {
              setFocused(false)
              onBlur(e)
            }}
            onChange={onChange}
          />
        ) : (
          <BaseInput
            as={"textarea"}
            id={name}
            rows={4}
            name={name}
            value={value}
            aria-label={ariaLabel}
            type={"text"}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={e => {
              setFocused(false)
              onBlur(e)
            }}
            onChange={onChange}
          />
        )}
      </RootInput>
    </FormControl>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  ariaLabel: PropTypes.string,
}

export default Input
