import styled from '@emotion/styled';
import { isArray } from 'util';

const Hero = styled.section`
	min-height: calc(
		${(props) => (props.height && isArray(props.height) ? props.height[1] : props.height || '100vh  - 120px')}
	);
	background-image: linear-gradient(
		120deg,
		${(props) => props.theme.colors.secondary.light} 0%,
		${(props) => props.theme.colors.secondary.main} 100%
	);
	padding-top: 160px;
	position: relative;
	overflow: hidden;

	@media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
		min-height: calc(
			${(props) => (props.height && isArray(props.height) ? props.height[0] : props.height || '100vh  - 80px')}
		);
		padding-top: 120px;
	}
`;

export default Hero;
