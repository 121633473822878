import React, { useState } from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/dist/emotion"
import { Formik } from "formik"
import axios from "axios"
import { ClipLoader } from "react-spinners"

import Container from "./Container"
import { hex2rgba, isEmptyObject } from "../utils"
import Button from "./Button"
import Input from "./Input"

const instance = axios.create({
  baseURL: process.env.API_CONTACT_BASE_URL,
  auth: {
    username: "ASConsulting_Group",
    password: process.env.API_PASSWORD_SEND_EMAIL,
  },
})

const StyledFormWrapper = styled.div`
  box-shadow: 0px 6px 6px -3px ${props =>
    hex2rgba(props.theme.colors.secondary.main, 0.2)},
    0px 10px 14px 1px
      ${props => hex2rgba(props.theme.colors.secondary.main, 0.14)},
    0px 4px 18px 3px
      ${props => hex2rgba(props.theme.colors.secondary.main, 0.1)};
  border-radius: 4px;
  padding: 40px 32px;
  transition: all 300ms;
  /* background: ${props => props.theme.colors.secondary.main}; */
  background-image: linear-gradient(
    120deg,
    ${props => props.theme.colors.secondary.light} 0%,
    ${props => props.theme.colors.secondary.main} 100%
  );
  position: relative;

  > h3 {
    text-align: center;
  }

  & p:first-of-type {
    text-align: center;
    color: ${props => props.theme.colors.common.white};
    font-size: 16px;
    margin-top: -16px;
  }
`

const StyledError = styled.div`
  font-size: 14px;
  color: red;
`

const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setLoading(true)
    console.log(values)

    instance
      .post("/contactForm_submit", values)
      .then(response => {
        console.log(response)
        resetForm()
        setSubmitting(false)
        setLoading(false)

        if (response.status == 200) {
          setSuccess(true)
        }
      })
      .catch(err => {
        console.log(err)
        setSubmitting(false)
        setLoading(false)
      })
  }

  return (
    <Container id="contact-us" sm>
      <Flex flexWrap="wrap" mx={[-2, -3]} py={"60px"}>
        <Box width={[1, 1 / 2, 1 / 2]} px={[1, 3]} my={[1, 2]} flex="1 1 auto">
          <StyledFormWrapper>
            <h3>Contact Us</h3>
            <p>Just tell us about your idea</p>

            <Formik
              initialValues={{
                name: "",
                company: "",
                email: "",
                phonenumber: "",
                subject: "",
                message: "",
              }}
              validate={values => {
                let errors = {}

                if (!values.name) {
                  errors.name = "Name is required"
                }

                if (!values.email) {
                  errors.email = "Email is required"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address"
                }

                if (
                  values.phonenumber &&
                  !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
                    values.phonenumber
                  )
                ) {
                  errors.phonenumber = "Invalid phone number"
                }

                if (!values.subject) {
                  errors.subject = "Subject is required"
                }

                if (!values.message) {
                  errors.message = "Message is required"
                }

                return errors
              }}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit} style={{ margin: 0 }}>
                    <Input
                      name="name"
                      label={"Name"}
                      ariaLabel={"place your name"}
                      placeholder={"your name"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <StyledError>{errors.name}</StyledError>
                    )}
                    <Input
                      name="company"
                      label={"Company"}
                      ariaLabel={"add your company"}
                      placeholder={"your company"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                    />
                    {errors.company && touched.company && (
                      <StyledError>{errors.company}</StyledError>
                    )}
                    <Input
                      name="email"
                      type="email"
                      label={"Email"}
                      ariaLabel={"input your email"}
                      placeholder="your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <StyledError>{errors.email}</StyledError>
                    )}
                    <Input
                      name="phonenumber"
                      type="tel"
                      label={"Phone number"}
                      ariaLabel={"add your phone number"}
                      placeholder="your phone number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phonenumber}
                    />
                    {errors.phonenumber && touched.phonenumber && (
                      <StyledError>{errors.phonenumber}</StyledError>
                    )}
                    <Input
                      name="subject"
                      label={"Subject"}
                      placeholder="subject"
                      ariaLabel={"place a subject"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                    {errors.subject && touched.subject && (
                      <StyledError>{errors.subject}</StyledError>
                    )}
                    <Input
                      name="message"
                      label={"Message"}
                      type="textarea"
                      ariaLabel={"add your message"}
                      placeholder="tell us about something"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    {errors.message && touched.message && (
                      <StyledError>{errors.message}</StyledError>
                    )}

                    <div style={{ textAlign: "center", marginTop: 24 }}>
                      {!isEmptyObject(errors) && (
                        <StyledError>Some field(s) have error(s)</StyledError>
                      )}

                      {success && (
                        <div
                          style={{
                            borderRadius: 4,
                            padding: 8,
                            color: "white",
                            background: "green",
                            marginBottom: 16,
                          }}
                        >
                          Your message has been received, thanks!
                        </div>
                      )}
                      <Button primary type="submit" disabled={isSubmitting}>
                        Send Message
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "rgba(255,255,255,0.5)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ClipLoader
                  sizeUnit={"px"}
                  size={80}
                  color={"#307986"}
                  loading
                />
                <div style={{ color: "#307986" }}>Sending...</div>
              </div>
            )}
          </StyledFormWrapper>
        </Box>
      </Flex>
    </Container>
  )
}

export default ContactForm
