import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { hex2rgba } from "../utils"

const StyledButton = styled.button`
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary.main} !important;
  background: ${props => props.theme.colors.common.white};
  padding: 10px 32px;
  border-radius: 20px;
  box-shadow: ${props => props.theme.shadows[1]};
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  transition: all 300ms;
  &:hover {
    box-shadow: ${props => props.theme.shadows[4]};
  }

  ${props =>
    (props.outline || props.outline == "true") &&
    css`
      border-radius: 32px;
      background: transparent;
      border: 2px solid ${props.theme.colors.primary.main};
      transition: all 300ms;

      &:hover {
        box-shadow: ${props.theme.shadows[4]};
        background: ${props.theme.colors.primary.main};
        color: ${props.theme.colors.common.white} !important;
      }
    `};

  ${props =>
    (props.primary || props.primary == "true") &&
    css`
      border-radius: 32px;
      border: 2px solid ${props.theme.colors.primary.main};
      background: ${props.theme.colors.primary.main};
      color: ${props.theme.colors.common.white} !important;
      transition: all 300ms;

      &:hover {
        box-shadow: ${props.theme.shadows[4]};
        background: transparent;
        color: ${props.theme.colors.primary.main} !important;
      }

      &:disabled {
        background: ${hex2rgba(props.theme.colors.primary.main, 0.12)};
        border-color: ${hex2rgba(props.theme.colors.primary.main, 0.12)};
        color: ${props.theme.colors.common.white} !important;
        cursor: not-allowed;
      }
    `};
`

const Button = ({ children, ...other }) => {
  return <StyledButton {...other}>{children}</StyledButton>
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
