import React from "react"
import styled from "@emotion/styled"

const StyledSVG = styled.svg`
  fill: ${props => props.customFill || "currentColor"};
`

const TwitterSVG = ({ ...props }) => {
  return (
    <StyledSVG
      enableBackground="new 0 0 510 510"
      viewBox="0 0 510 510"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g id="post-twitter">
          <path
            d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
			M400.35,186.15c-2.55,117.3-76.5,198.9-188.7,204C165.75,392.7,132.6,377.4,102,359.55c33.15,5.101,76.5-7.649,99.45-28.05
			c-33.15-2.55-53.55-20.4-63.75-48.45c10.2,2.55,20.4,0,28.05,0c-30.6-10.2-51-28.05-53.55-68.85c7.65,5.1,17.85,7.65,28.05,7.65
			c-22.95-12.75-38.25-61.2-20.4-91.8c33.15,35.7,73.95,66.3,140.25,71.4c-17.85-71.4,79.051-109.65,117.301-61.2
			c17.85-2.55,30.6-10.2,43.35-15.3c-5.1,17.85-15.3,28.05-28.05,38.25c12.75-2.55,25.5-5.1,35.7-10.2
			C425.85,165.75,413.1,175.95,400.35,186.15z"
          />
        </g>
      </g>
    </StyledSVG>
  )
}

export default TwitterSVG
