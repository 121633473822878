import styled from "@emotion/styled"
import css from "@emotion/css"

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 48px;
  color: ${props => props.theme.colors.common.white};
  text-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 44px;
  }
  text-transform: uppercase;
`

export const H2 = styled.h2`
  color: ${props => props.theme.colors.secondary.dark};

  ${props =>
    props.center &&
    css`
      text-align: center;

      @media (max-width: ${props.theme.breakpoints[0]}) {
        text-align: left;
      }
    `};
`

export const H3 = styled.h3`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  color: white;

  ${props =>
    props.secondary &&
    css`
      font-weight: 700;
      font-size: 18px;
      color: ${props.theme.colors.secondary.main};
    `};
`

export const H4 = styled.h4`
  z-index: 1;
  text-align: ${props => (props.left ? "left" : "center")};
  color: white;
  font-size: 20px;
  text-shadow: 0px 3px 15px rgba(255, 255, 255, 0.2);
  margin: 0;

  ${props =>
    props.secondary &&
    css`
      color: ${props.theme.colors.secondary.main};
    `};
`
