import { css } from '@emotion/core';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

import Logo from './logo';
import Navigation from './navigation';
import { hex2rgba } from '../utils';

const StyledHeader = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: transparent;
	transition: background-color 300ms ease-in;

	${(props) => props.hasBackground && css`background-color: ${hex2rgba(props.theme.colors.primary.main, 0.65)};`};
`;

const Header = () => {
	const [ trigger, setTrigger ] = useState(false);

	useEffect(
		() => {
			const handleScroll = () => {
				if (window.scrollY > 10 && !trigger) {
					setTrigger(true);
				} else if (window.scrollY < 10 && trigger) {
					setTrigger(false);
				}
			};

			window.addEventListener('scroll', handleScroll, { passive: true });

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		},
		[ trigger ]
	);

	return (
		<StyledHeader hasBackground={trigger}>
			<Flex alignItems={'center'} justifyContent={'space-between'} mx={4} my={'auto'} py={3}>
				<Link to="/">
					<div style={{ width: '40px', height: '40px' }}>
						<Logo />
					</div>
				</Link>

				<Navigation />
			</Flex>
		</StyledHeader>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
