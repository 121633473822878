import React from "react"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import Container from "./Container"
import { Link } from "gatsby"
import Logo from "./logo"
import TwitterSVG from "../svg/twitter"
import FBSVG from "../svg/facebook"
import LISVG from "../svg/linkedin"
import { hex2rgba } from "../utils"

const BottomSection = styled.section`
  background-image: linear-gradient(
    120deg,
    ${props => props.theme.colors.secondary.light} 0%,
    ${props => props.theme.colors.secondary.main} 100%
  );
  padding: 32px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 24px 0;
  }
`

const StyledFooterNav = styled.nav`
  text-align: right;
  > a {
    font-size: 14px;
    color: ${props => props.theme.colors.common.white};
    margin-left: 24px;
    margin-right: 24px;
    text-decoration: none;
    &:last-child {
      margin: 0;
    }
    transition: all 300ms;
    &:hover {
      color: ${props => props.theme.colors.primary.main};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-align: center;
    > a {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
`

const StyledCR = styled(Box)`
  color: ${props => props.theme.colors.common.white};
  font-size: 15px;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-align: center;
  }
`

const FooterTitle = styled.div`
  color: ${props => props.theme.colors.secondary.dark};
  margin-bottom: 16px;
  font-weight: 700;
`

const FooterLink = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.colors.common.white};
  transition: all 300ms;
  &:hover {
    color: ${props => props.theme.colors.primary.main};
  }
  display: block;
  margin-bottom: 4px;
`

const StyledA = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.colors.common.white};
  transition: all 300ms;
  &:hover {
    color: ${props => props.theme.colors.primary.main};
  }
  display: block;
  margin-bottom: 4px;
`

const FooterLabel = styled.div`
  color: white;
  font-size: 14px;
  > a {
    text-decoration: none;
    color: inherit;
    transition: all 300ms;
    &:hover {
      color: ${props => props.theme.colors.primary.main};
    }
  }
`

const SocialGroup = styled.div`
  // margin-top: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    justify-content: flex-start;
    margin-top: 16px;
  }

  > a {
    margin-right: 8px;
    text-decoration: none;
    color: inherit;
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${props => props.theme.colors.primary.main};

      > svg {
        box-shadow: 0px 0px 16px 0px
          ${props => hex2rgba(props.theme.colors.primary.main, 0.67)};
      }
    }
  }
`

const Footer = () => {
  return (
    <BottomSection>
      <Container>
        <footer style={{ marginBottom: 24 }}>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 8]} px={[1, 3]} mb={[2]}>
              <div style={{ width: 40 }}>
                <Logo />
              </div>
            </Box>
            <Box width={[1, 1 / 4]} px={[1, 3]} mb={[2]}>
              <FooterTitle>Contact</FooterTitle>
              <FooterLabel>
                3414 E Atlantic Blvd, Pompano Beach FL 33062
              </FooterLabel>
              <FooterLabel>
                <a
                  aria-label="send a whatsapp message"
                  href="https://api.whatsapp.com/send?phone=+19546126075"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (954) 612 6075
                </a>
              </FooterLabel>
              <FooterLabel>
                Calle 7 26 Reforma Soc, Miguel Hidalgo, 11650 Ciudad de México,
                CDMX
              </FooterLabel>
              <FooterLabel>
                <a href="mailto:plukkemx@gmail.com">plukkemx@gmail.com</a>
              </FooterLabel>
              <FooterLabel>
                <a
                  aria-label="send a contact whatsapp message"
                  href="https://api.whatsapp.com/send?phone=+525535552173"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (55) 3555 2173
                </a>
              </FooterLabel>
            </Box>
            <Box width={[1, 1 / 4]} px={[1, 3]} mb={[2]}>
              <FooterTitle>Services</FooterTitle>
              <FooterLink to="/services/web-development">
                Web development
              </FooterLink>
              <FooterLink to="/services/mobile-development">
                Mobile development
              </FooterLink>
              <FooterLink to="/services/alexa-development">
                Alexa Skill development
              </FooterLink>
              <FooterLink to="/services/chatbot-development">
                Chatbot development
              </FooterLink>
            </Box>
            <Box width={[1, 1 / 4]} px={[1, 3]} mb={[2]}>
              <FooterTitle>Solutions</FooterTitle>
              <StyledA
                aria-label="check our one click solution"
                href="https://www.notion.so/plukke/PaaS-Plukke-One-Click-d04347d6d54046e39122ec8678e8d809"
                target="_blank"
                rel="noopener noreferrer"
              >
                One-Click
              </StyledA>
            </Box>
          </Flex>
        </footer>
        <Flex flexWrap="wrap">
          <StyledCR width={[1, 1 / 2, 1 / 2]}>
            © Plukke Tech {new Date().getFullYear()}. All rights reserved.
          </StyledCR>
          <Box width={[1, 1 / 2, 1 / 2]}>
            <StyledFooterNav>
              {/* <Link to="/terms">Terms of use</Link>
              <Link to="/privacy">Privacy</Link> */}
              <SocialGroup>
                <a
                  aria-label="go to our facebook page"
                  href="https://www.facebook.com/plukketech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FBSVG width={28} />
                </a>
                <a
                  aria-label="go to our linkedin page"
                  href="https://www.linkedin.com/company/plukke"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LISVG width={28} />
                </a>
                <a
                  aria-label="go to our twitter account"
                  href="https://twitter.com/plukkemx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterSVG width={28} />
                </a>
              </SocialGroup>
            </StyledFooterNav>
          </Box>
        </Flex>
      </Container>
    </BottomSection>
  )
}

export default Footer
