import { Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import css from "@emotion/css"

const Container = styled(Box)`
  position: relative;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: ${({ theme }) => theme.breakpoints[1]};
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-width: ${({ theme }) => theme.breakpoints[2]};
    padding-left: 32px;
    padding-right: 32px;
  }

  ${props =>
    props.sm &&
    css`
      max-width: ${props.theme.breakpoints[0]} !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
    `}
`

Container.defaultProps = {
  mx: "auto",
  px: 16,
  width: "100%",
  boxSizing: "border-box",
}

export default Container
