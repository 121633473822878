/* eslint-disable react/prop-types */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';

import Header from './header';
import theme from '../utils/theme';
import GlobalStyles from './styles/global';
import Footer from './footer';
import StandardSection from './StandardSection';
import ContactForm from './ContactForm';

if (typeof window !== 'undefined') {
	// eslint-disable-next-line global-require
	require('smooth-scroll')('a[href*="#"]');
}

const Root = styled.div`
	width: 100%;
	min-height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
`;

const Main = styled.main`flex: 1;`;

const VerticalLinksGroup = styled.div`
	position: absolute;
	top: 180px;
	left: 28px;
`;

const VerticalLink = styled.a`
	writing-mode: vertical-rl;
	text-orientation: mixed;
	transform: rotate(180deg);
	display: block;
	font-size: 14px;
	color: ${(props) => props.theme.colors.common.white};
	text-decoration: none;
	transition: all 300ms;
	&:hover {
		color: ${(props) => props.theme.colors.primary.main};
	}

	margin-top: 16px;

	@media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
		display: none;
	}
`;

const MenuAdapter = ({ className, ...props }) => {
	const cls = `${className}`;
	const buttonClassName = `${className}__burger-button`;
	const barsClassName = `${className}__burger-bars`;
	const crossButton = `${className}__cross-button`;
	const menu = `${className}__menu`;

	return (
		<Menu
			className={cls}
			burgerButtonClassName={buttonClassName}
			burgerBarClassName={barsClassName}
			crossButtonClassName={crossButton}
			menuClassName={menu}
			{...props}
		/>
	);
};

const StyledMenu = styled(MenuAdapter)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
    flex-direction: column;
  }

  &.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 26px;
    top: 24px;
  }

  &.bm-burger-bars {
    background: ${(props) => props.theme.colors.common.white};
  }

  &.bm-burger-bars-hover {
    background: #a90000;
  }

  &.bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
	Sidebar wrapper styles
	Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
	*/
  &.bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  &.bm-menu {
    background: ${(props) => props.theme.colors.common.white};
    padding: 32px 0;
    font-size: 16px;
  }

  /* Morph shape necessary with bubble or elastic */
  //   .bm-morph-shape {
  //     fill: #373a47;
  //   }

  /* Wrapper for item list */
  .bm-item-list {
    padding: 0 24px;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    text-decoration: none;
    outline: none;
    color: ${(props) => props.theme.colors.primary.main};
    padding: 8px 0;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  /* Styling of overlay */
  &.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const Layout = ({ children }) => {
	const [ isMenuOpen, setMenuOpen ] = useState(false);
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<ThemeProvider theme={theme}>
			<Root>
				<GlobalStyles />
				<Header siteTitle={data.site.siteMetadata.title} />
				<StyledMenu right isOpen={isMenuOpen} onStateChange={(state) => setMenuOpen(state.isOpen)}>
					<Link className="menu-item" to="/" onClick={() => setMenuOpen(false)}>
						Home
					</Link>
					<Link className="menu-item" to="/#services" onClick={() => setMenuOpen(false)}>
						Services
					</Link>
					<a
						className="menu-item"
						href="https://www.notion.so/plukke/PaaS-Plukke-One-Click-d04347d6d54046e39122ec8678e8d809"
						target="_blank"
						rel="noopener noreferrer"
					>
						Solutions
					</a>
					<Link className="menu-item" to="/portfolio" onClick={() => setMenuOpen(false)}>
						Portfolio
					</Link>
					<Link className="menu-item" to="/#contact-us" onClick={() => setMenuOpen(false)}>
						Contact Us
					</Link>
				</StyledMenu>
				<Main>{children}</Main>
				<StandardSection>
					<ContactForm />
				</StandardSection>
				<Footer />

				<VerticalLinksGroup>
					<VerticalLink
						href="https://www.linkedin.com/company/plukke"
						target="_blank"
						rel="noopener noreferrer"
					>
						LinkedIn
					</VerticalLink>
					<VerticalLink href="https://www.facebook.com/plukketech/" target="_blank" rel="noopener noreferrer">
						Facebook
					</VerticalLink>
				</VerticalLinksGroup>
			</Root>
		</ThemeProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
